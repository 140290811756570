import React, { useState } from "react";
import styled from "styled-components";

const ContentOpenerContainer = styled.div`
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  max-height: ${(props) => (props.isOpen ? "800px" : "50px")};
  transition: max-height 500ms ease;
  overflow: hidden;
  color: #0e132b;
  margin-top: -1px;

  @media (max-width: 1200px) {
    font-size: 16px;
    max-height: ${(props) => (props.isOpen ? "1250px" : "50px")};
  }
`;

const ContentOpenerHeader = styled.div`
  height: 50px;
  cursor: pointer;
  transition: background 300ms ease;

  &:hover {
      background: rgba(0,0,0,0.25);
      background: rgba(82, 113, 255, 0.25);
  }

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    position: relative;
  }
`;
const Plus = styled.div`
  font-size: 40px;
  font-weight: 100;
  line-height: 50px;
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
  float: left;
  transform: rotate(${(props) => (props.isOpen ? "45deg" : 0)});
  transition: transform 0.5s ease;

  @media (max-width: 1200px) {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Title = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  padding-right: 12px;

  @media (max-width: 1200px) {
    line-height: 25px;
    width: calc(100% - 50px);
  }
`;

const Content = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  padding-right: 12px;
  padding-left: 12px;
  text-align: justify;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export default function ContentOpener({ title, content, style }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ContentOpenerContainer isOpen={isOpen} {...style}>
      <ContentOpenerHeader onClick={() => setIsOpen(!isOpen)}>
        <Plus isOpen={isOpen}>+</Plus>
        <Title>{title}</Title>
      </ContentOpenerHeader>
      <Content>{content}</Content>
    </ContentOpenerContainer>
  );
}
