import React, { useState } from "react";
import styled from "styled-components";
import ContentOpener from "./ContentOpener";

const OurCoursesContent = styled.div`
  margin-bottom: 40px;
  width: 1020px;
  margin: 0 auto;
  color: #0e132b;
  padding-top: 80px;

  @media (max-width: 1200px) {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 20px 0 20px;
  }
`;
const OurCourseContainer = styled.div`
  z-index: 0;
  background: url("/techbg3.png");
  background-size: cover;
  background-position: center center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #0e132b;
  margin: 0;
  padding: 0 0 28px 0;

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const CoursesContainer = styled.div`
  // display: flex;
  // justify-content: space-between;
  padding: 20px 0 40px 0;
`;

const Course = styled.div`
  padding: 28px;
  box-shadow: 2px 4px 25px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  margin: 20px 0 40px;
  position: relative;
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

const CourseTitleBG = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
  height: 48px;
  width: 330px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: 1;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
`;

const FECourseTitleBG = styled(CourseTitleBG)`
  background: #445ed5;
`;

const FSCourseeTitleBG = styled(CourseTitleBG)`
  background: #293980;
`;

const CourseTitle = styled.h3`
  margin-top: 6px;
  font-size: 26px;
  color: white;
  position: relative;
  z-index: 2;
`;

const CourseDescription = styled.div`
  margin-bottom: 8px;
`;

const CourseDuration = styled.div`
  margin-top: 24px;
  font-weight: 700;
  line-height: 20px;
`;

const CourseOpening = styled.div`
  margin-top: 12px;
  font-weight: 700;
  line-height: 20px;
`;

const CourseDetails = styled.div`
  padding: 32px 0;
`;

const CourseDetailsOpener = styled(ContentOpener)`
  margin-bottom: 12px;
`;

const ContactUsButton = styled.div`
  height: 55px;
  width: 233px;
  text-align: center;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 24px;
  font-weight: 700;
  border-radius: 8px;
  line-height: 55px;
  margin: auto;
  cursor: pointer;
  transition: box-shadow 300ms ease;

  &:hover{ 
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
  }
`;

const FEContactUsButton = styled(ContactUsButton)`
  background: #445ed5;
`;

const FSContactUsButton = styled(ContactUsButton)`
  background: #293980;
`;

const courses = [
  {
    name: "קורס בסיס - Front-End",
    description:
      "הקורס מיועד לחסרי ניסיון בתחום המעוניינים ללמוד את הבסיס של עולמות פיתוח צד לקוח - החל מבניית אתרים סטטיים ב-HTML ו-CSS, ועד לבניית אפליקציות Web (Single Page Applications) מתקדמות ב-Javascript ו-React. ",
    fullDescription: (
      <div>
        הקורס בנוי במודל למידה חכם המשלב 
         <b> הרצאות מוקלטות</b>
         , המועלות לצפייה מדי
        שבוע, 
        <b> ושיעורי תרגול בקבוצות קטנות </b>
         בזום עם מרצה הקורס.
        <br /><br/>
        <u>הרצאות מוקלטות</u>
        <br />
        ההרצאות המוקלטות הן הרצאות מתוכננות בקפידה, ומדייקות את התוכן המועבר בהתאם לסטטוס הידע של הסטודנטים בקורס עד לאותה נקודה.
        מדי שבוע, עם סיום שיעורי התרגול בזום, תועלה
         <b> הרצאה חדשה שאורכה כשעה</b>
        , עם נושאים חדשים בהתאם לסילבוס הקורס. <br />
        על מנת לתרגל את תוכן השיעור, יינתנו 
        <b> תרגילי בית לאותו שבוע</b>
        . תרגילי הבית יתחלקו לתרגילי חובה והעשרה, על מנת לאפשר לסטודנטים רמת תרגול הכרחית, לצד תרגול מעמיק
        בהתאם לזמנו האישי של כל סטודנט.<br/><br/>

        <u>שיעורים בזום</u><br/>
        השיעור הראשון יהיה 
        <b> שיעור היכרות </b>
         בו המרצה והסטודנטים יכירו, ויוצגו כלי
        העבודה השונים ודרכי התקשורת של הקבוצה: <br />
         קבוצת וואטסאפ, Slack, וההרצאות המוקלטות + תוכן ושאילת שאלות
        בפייסבוק.<br/>
         בנוסף בשיעור זה יוצעו לסטודנטים תוכניות רשות
        <b> להגברת מחוייבות:</b>
        <br />
        <ul>
          <li>
            <b>תוכנית ה-Buddies </b>
             - לכל סטודנט מוצמד באדי (סטודנט אחר) למהלך הקורס,
            ומטרתם לעזור אחד להתמיד בלמידה, לעזור בתרגילי בית, להתייעץ
            וכו׳.
          </li>
          <li>
            <b>מפגשי תרגול בקבוצה </b>
             - הסטודנטים המעוניינים יפגשו בזום בזמן קבוע מראש
            כדי לתרגל יחד ולשאול אחד את השני שאלות.
          </li>
        </ul>
        לאחר השיעור הראשון, שאר השיעורים נועדים לתרגול ולמעבר על תרגילי בית. שיעורים אלה נותנים הזדמנות מדי שבוע להעלות שאלות ואתגרים כחלק מהלמידה - 
        הן בתרגילי הבית והן בתכנים המועלים בהרצאות המוקלטות.
        <br />
        <br />
        <u>ליווי אישי</u><br />
        אחת לחודש מרצה הקורס מקיים
         <b> שיחה אישית </b>
         עם כל סטודנט/ית. מטרת השיחה היא ללוות את הסטודנטים, לתת מענה אישי לצרכים ולאתגרים של כל סטודנט, לייעץ ולתת הכוונה להמשך הקורס.
        <br/><br/>
        <u>מהלך השבוע</u><br/>
        במהלך השבוע, כל סטודנט בזמנים הנוחים לו, 
        <b> צופה בהרצאה של אותו השבוע ופותר את תרגילי
        הבית. </b>
         תרגילי הבית מחולקים לתרגילי חובה ותרגילי העשרה, על מנת לאפשר
        לסטודנטים לתרגל במידה הרצויה. את תרגילי הבית מגישים לפני שיעור
        התרגול בזום. 
        במידה ובמהלך הלמידה נתקלים בקשיים - ניתן להיעזר בחברי הקבוצה ובמרצה בקבוצת הפייסבוק.
        <br />
        <br />
        <u>מהלך הקורס</u><br/>
        הקורס נמשך 25 שבועות - בהם 21 שעות מוקלטות, 36 שעות תרגול עם המרצה ו-6
        מפגשים אישיים עם המרצה של חצי שעה כל אחד.
        <br />
        במהלך הקורס, מעבר לתרגילי הבית, הסטודנטים עובדים על פרוייקט בינוני באמצע
        הקורס, ופרוייקט גדול בסופו (פרוייקט זה ניתן לעשות בקבוצות).
      </div>
    ),
    sylabus: [
      ` מבוא לקורס, הקמת סביבת עבודה, עבודה עם ChatGPT ומבוא ל-  JS & HTML & CSS`,
      `    סלקטורים ותכונות ב-CSS, אלמנטים שונים ב-HTML `,
      `טפסים, DOM & Developer Tools`,
      `המשך סלקטורים ותכונות מתקדמות + CSS - Positions, displays, Flexbox,`,
      `מבוא ל- Javascript - JS basics, variables and data types`,
      `פונקציות ב-Javascript + עבודה דינמית עם טפסים`,
      `תנאים, ביטויים ואופרטורים`,
      `לולאות`,
      `מערכים, אובייקטים ו-Scope`,
      `פונקציות מסדר גבוה ו-Iterators`,
      `מניפולציות על ה-DOM`,
      `ניהול גרסאות באמצעות GIT`,
      `פרויקט אמצע`,
      `שימוש בספריות חיצוניות - npm, package.json - דוגמת lodash`,
      `מבוא ל-React ו-Create-react-app -  JSX ומבוא לקומפוננטות `,
      `חלוקה לקומפוננטות, שימוש ב-Props`,
      `ניהול State ו-Hooks בעבודה עם useState ו-useEffect`,
      `הסבר על life cycle, מתי קומפוננטה מתרנדרת מחדש`,
      `עוד סוגי React Hooks- useReducer/ useCallback / useMemo`,
      `כתיבה של Custom Hooks `,
      `ניהול State באפליקציות מורכבות context / redux`,
      `העלאת פרוייקט לרשת `,
      `פרוייקט גמר`
    ],
    requirements: 
    <div>
    הקורס מיועד לחסרי רקע בתחום, בעלי רמת אנגלית טובה ויכולת אנליטית. <br/>
    יש להצטייד במחשב נייח או נייד, מצלמה ומיקרופון לצורך השיעורים בזום. 
    </div>,
    duration: "25 שבועות",
    opening: "13/11/2023",
    cost: "7,900",
    tempCost: "6,900",
    reasonTempCost: <span>מחיר השקה מקנה הנחה של <b>1,000 ש״ח</b> לנרשמים עד ל-5.11.23.</span>,
  },
  {
    name: "קורס הרחבה - Front-End",
    description:
    `
      הקורס מיועד לבעלי רקע בסיסי בפיתוח Front-End וניסיון עם React. מטרת הקורס להעמיק ביכולות צד לקוח מתקדמות, כתיבת קוד ב-TypeScript, אנימציות, אלגוריתמים ומבני נתונים, הבנת ׳מאחורי הקלעים׳ של הדפדפן, REST API, עבודה עם כלי עיצוב ופיתוח מתקדמים ועוד.
    `,
    fullDescription: (
      <div>
      הקורס בנוי במודל למידה חכם המשלב 
       <b> הרצאות מוקלטות</b>
       , המועלות לצפייה מדי
      שבוע, 
      <b> ושיעורי תרגול בקבוצות קטנות </b>
       בזום עם מרצה הקורס.
      <br /><br/>
      <u>הרצאות מוקלטות</u>
      <br />
      ההרצאות המוקלטות הן הרצאות מתוכננות בקפידה, ומדייקות את התוכן המועבר בהתאם לסטטוס הידע של הסטודנטים בקורס עד לאותה נקודה.
      מדי שבוע, עם סיום שיעורי התרגול בזום, תועלה
       <b> הרצאה חדשה שאורכה כשעה</b>
      , עם נושאים חדשים בהתאם לסילבוס הקורס. <br />
      על מנת לתרגל את תוכן השיעור, יינתנו 
      <b> תרגילי בית לאותו שבוע</b>
      . תרגילי הבית יתחלקו לתרגילי חובה והעשרה, על מנת לאפשר לסטודנטים רמת תרגול הכרחית, לצד תרגול מעמיק
      בהתאם לזמנו האישי של כל סטודנט.<br/><br/>

      <u>שיעורים בזום</u><br/>
      השיעור הראשון יהיה 
      <b> שיעור היכרות </b>
       בו המרצה והסטודנטים יכירו, ויוצגו כלי
      העבודה השונים ודרכי התקשורת של הקבוצה: <br />
       קבוצת וואטסאפ, Slack, וההרצאות המוקלטות + תוכן ושאילת שאלות
      בפייסבוק.<br/>
       בנוסף בשיעור זה יוצעו לסטודנטים תוכניות רשות
      <b> להגברת מחוייבות:</b>
      <br />
      <ul>
        <li>
          <b>תוכנית ה-Buddies </b>
           - לכל סטודנט מוצמד באדי (סטודנט אחר) למהלך הקורס,
          ומטרתם לעזור אחד להתמיד בלמידה, לעזור בתרגילי בית, להתייעץ
          וכו׳.
        </li>
        <li>
          <b>מפגשי תרגול בקבוצה </b>
           - הסטודנטים המעוניינים יפגשו בזום בזמן קבוע מראש
          כדי לתרגל יחד ולשאול אחד את השני שאלות.
        </li>
      </ul>
      לאחר השיעור הראשון, שאר השיעורים נועדים לתרגול ולמעבר על תרגילי בית. שיעורים אלה נותנים הזדמנות מדי שבוע להעלות שאלות ואתגרים כחלק מהלמידה - 
      הן בתרגילי הבית והן בתכנים המועלים בהרצאות המוקלטות.
      <br />
      <br />
      <u>ליווי אישי</u><br />
      אחת לחודש מרצה הקורס מקיים
       <b> שיחה אישית </b>
       עם כל סטודנט/ית. מטרת השיחה היא ללוות את הסטודנטים, לתת מענה אישי לצרכים ולאתגרים של כל סטודנט, לייעץ ולתת הכוונה להמשך הקורס.
      <br/><br/>
      <u>מהלך השבוע</u><br/>
      במהלך השבוע, כל סטודנט בזמנים הנוחים לו, 
      <b> צופה בהרצאה של אותו השבוע ופותר את תרגילי
      הבית. </b>
       תרגילי הבית מחולקים לתרגילי חובה ותרגילי העשרה, על מנת לאפשר
      לסטודנטים לתרגל במידה הרצויה. את תרגילי הבית מגישים לפני שיעור
      התרגול בזום. 
      במידה ובמהלך הלמידה נתקלים בקשיים - ניתן להיעזר בחברי הקבוצה ובמרצה בקבוצת הפייסבוק.
      <br />
      <br />
      <u>מהלך הקורס</u><br/>
        הקורס נמשך 25 שבועות - בהם 16 שעות מוקלטות, 36 שעות תרגול עם המרצה ו-6
        מפגשים אישיים עם המרצה של חצי שעה כל אחד.
        <br />
       במהלך הקורס, מעבר לשיעורי הבית, הסטודנטים עובדים על שני פרוייקטים בינוניים, ופרוייקט גדול בסופו. את הפרוייקטים ניתן לעשות בקבוצות.
      </div>
    ),
    sylabus: [
      `מבוא לקורס ול-Typescript`,
      `איך Javascript עובד מאחורי הקלעים?`,
      `אלגוריתמים ומבני נתונים - חלק א׳`,
      `אלגוריתמים ומבני נתונים - חלק ב׳`,
      `עבודה מול שרת - REST API`,
      `ניהול שגיאות`,
      `ניהול דאטה משרת - React query`,
      `פרוייקט I`,
      `כתיבת CSS בתוך JS - עבודה עם Styled Components`,
      `אנימציות - CSS & JS`,
      `שימוש ב-Cookies ו-LocalStorage`,
      `עבודה עם Design System ומול מעצבים ב-Figma`,
      `ניהול ספריית קומפוננטות פנימית - Storybook`,
      `פרוייקט II`,
      `רספונסיביות - התאמה למובייל ולמכשירים שונים`,
      `ביצועים - מכשירים שונים, דפדפנים שונים`,
      `כתיבת טסטים`,
      `פרוייקט גמר`,
      `הכנה לראיונות עבודה`,
      
    ],
    requirements: 
    <div>
    הקורס מיועד לבעלי רקע בסיסי בתחום, בעלי רמת אנגלית טובה ויכולת אנליטית. <br/>
    מתאים למסיימי קורס Front-End הבסיסי באתר, או לבעלי רקע התואם לרקע הנרכש בקורס. <br/>
    יש להצטייד במחשב נייח או נייד, מצלמה ומיקרופון לצורך השיעורים בזום. 
    </div>,
    duration: "25 שבועות",
    opening: "02/06/2024",
    cost: "7,900",
    tempCost: "6,900",
    reasonTempCost: <span>מחיר השקה מקנה הנחה של <b>1,000 ש״ח</b> לנרשמים עד ל-5.11.23.</span>,
  },
  {
    name: "קורס הרחבה - Full Stack",
    description:
      "הקורס מיועד לבעלי רקע בסיסי בפיתוח Front-End וניסיון עם React. מטרת הקורס להעמיק ביכולות צד לקוח וצד שרת, כתיבת קוד ב-TypeScript, אלגוריתמים ומבני נתונים, הבנת ׳מאחורי הקלעים׳ של הקוד הרץ בשרת ובדפדפן, REST API, הקמה ופיתוח שרתים ב-Node, Express, ושימוש ב-ORM לעבודה מול מסד נתונים רלציוני PostGres.",
    fullDescription: (
      <div>
      הקורס בנוי במודל למידה חכם המשלב 
       <b> הרצאות מוקלטות</b>
       , המועלות לצפייה מדי
      שבוע, 
      <b> ושיעורי תרגול בקבוצות קטנות </b>
       בזום עם מרצה הקורס.
      <br /><br/>
      <u>הרצאות מוקלטות</u>
      <br />
      ההרצאות המוקלטות הן הרצאות מתוכננות בקפידה, ומדייקות את התוכן המועבר בהתאם לסטטוס הידע של הסטודנטים בקורס עד לאותה נקודה.
      מדי שבוע, עם סיום שיעורי התרגול בזום, תועלה
       <b> הרצאה חדשה שאורכה כשעה</b>
      , עם נושאים חדשים בהתאם לסילבוס הקורס. <br />
      על מנת לתרגל את תוכן השיעור, יינתנו 
      <b> תרגילי בית לאותו שבוע</b>
      . תרגילי הבית יתחלקו לתרגילי חובה והעשרה, על מנת לאפשר לסטודנטים רמת תרגול הכרחית, לצד תרגול מעמיק
      בהתאם לזמנו האישי של כל סטודנט.<br/><br/>

      <u>שיעורים בזום</u><br/>
      השיעור הראשון יהיה 
      <b> שיעור היכרות </b>
       בו המרצה והסטודנטים יכירו, ויוצגו כלי
      העבודה השונים ודרכי התקשורת של הקבוצה: <br />
       קבוצת וואטסאפ, Slack, וההרצאות המוקלטות + תוכן ושאילת שאלות
      בפייסבוק.<br/>
       בנוסף בשיעור זה יוצעו לסטודנטים תוכניות רשות
      <b> להגברת מחוייבות:</b>
      <br />
      <ul>
        <li>
          <b>תוכנית ה-Buddies </b>
           - לכל סטודנט מוצמד באדי (סטודנט אחר) למהלך הקורס,
          ומטרתם לעזור אחד להתמיד בלמידה, לעזור בתרגילי בית, להתייעץ
          וכו׳.
        </li>
        <li>
          <b>מפגשי תרגול בקבוצה </b>
           - הסטודנטים המעוניינים יפגשו בזום בזמן קבוע מראש
          כדי לתרגל יחד ולשאול אחד את השני שאלות.
        </li>
      </ul>
      לאחר השיעור הראשון, שאר השיעורים נועדים לתרגול ולמעבר על תרגילי בית. שיעורים אלה נותנים הזדמנות מדי שבוע להעלות שאלות ואתגרים כחלק מהלמידה - 
      הן בתרגילי הבית והן בתכנים המועלים בהרצאות המוקלטות.
      <br />
      <br />
      <u>ליווי אישי</u><br />
      אחת לחודש מרצה הקורס מקיים
       <b> שיחה אישית </b>
       עם כל סטודנט/ית. מטרת השיחה היא ללוות את הסטודנטים, לתת מענה אישי לצרכים ולאתגרים של כל סטודנט, לייעץ ולתת הכוונה להמשך הקורס.
      <br/><br/>
      <u>מהלך השבוע</u><br/>
      במהלך השבוע, כל סטודנט בזמנים הנוחים לו, 
      <b> צופה בהרצאה של אותו השבוע ופותר את תרגילי
      הבית. </b>
       תרגילי הבית מחולקים לתרגילי חובה ותרגילי העשרה, על מנת לאפשר
      לסטודנטים לתרגל במידה הרצויה. את תרגילי הבית מגישים לפני שיעור
      התרגול בזום. 
      במידה ובמהלך הלמידה נתקלים בקשיים - ניתן להיעזר בחברי הקבוצה ובמרצה בקבוצת הפייסבוק.
      <br />
      <br />
      <u>מהלך הקורס</u><br/>
        הקורס נמשך 25 שבועות - בהם 18 שעות מוקלטות, 36 שעות תרגול עם המרצה ו-6
        מפגשים אישיים עם המרצה של חצי שעה כל אחד.
        <br />
       במהלך הקורס, מעבר לשיעורי הבית, הסטודנטים עובדים על פרוייקט אמצע בינוני ופרוייקט גדול בסופו. את הפרוייקטים ניתן לעשות בקבוצות.
      </div>
    ),
    sylabus: [
      `מבוא לקורס ול-Typescript`,
      `איך Javascript עובד מאחורי הקלעים?`,
      `אלגוריתמים ומבני נתונים - חלק א׳`,
      `אלגוריתמים ומבני נתונים - חלק ב׳`,
      `עבודה מול שרת - REST API`,
      `ניהול שגיאות`,
      `ניהול דאטה משרת - React query`,
      `פרוייקט אמצע`,
      `מבוא ל-Node ו-Express`,
      `מבנה שרת ותפקידים שונים - controllers, services, repositories`,
      `בניית שרת מבוסס REST`,
      `מבוא לבסיסי נתונים - רלציוניים ולא רלציוניים`,
      `עבודה עם בסיס נתונים רלציוני- PostGres וקריאות SQL`,
      `מהו ORM ועבודה עם TypeORM`,
      `סוגי שגיאות וניהולן בשרת`,
      `ניהול משתמשים ובקשות מאובטחות`,
      `רישום משתמשים דרך צד ג׳ + שימוש ב-Cookies`,
      `כתיבת טסטים`,
      `פרוייקט גמר`,
      `הכנה לראיונות עבודה`,
      
    ],
    requirements: 
    <div>
    הקורס מיועד לבעלי רקע בסיסי בתחום, בעלי רמת אנגלית טובה ויכולת אנליטית. <br/>
    מתאים למסיימי קורס Front-End הבסיסי באתר, או לבעלי רקע התואם לרקע הנרכש בקורס. <br/>
    יש להצטייד במחשב נייח או נייד, מצלמה ומיקרופון לצורך השיעורים בזום. 
    </div>,
    duration: "25 שבועות",
    opening: "02/06/2024",
    cost: "7,900",
    tempCost: "6,900",
    reasonTempCost: <span>מחיר השקה מקנה הנחה של <b>1,000 ש״ח</b> לנרשמים עד ל-5.11.23.</span>,
  }
];

const PriceSectionContainer = styled.div`

`

const Price = styled.div`
  display: inline;
  font-size: 20px;
  font-weight: 700;
  padding: 0 4px;
`

const OldPrice = styled.div`
  text-decoration: line-through;
  display: inline-block;
  padding: 0 4px;
  background: #5271ff;
  color: white;
  border-radius: 4px;
  transform: translateY(-2px);
`

const ReasonTempCost = styled.div`

`

const LeavingCondition = styled.div`
  margin-top: 12px;
  font-size: 14px;
`

const PriceSection = ({ cost, tempCost, reasonTempCost }) => (
  <PriceSectionContainer>
    מחיר הקורס: 
    <Price>{tempCost} ש״ח</Price> 
    <OldPrice>{cost} ש״ח</OldPrice>
    <ReasonTempCost>
      {reasonTempCost}
    </ReasonTempCost>
    <LeavingCondition>
      * להבטחת מקום הסטודנט/ית בקורס יש לשלם מקדמה של 600 ש״ח (מחיר הקורס כולל בתוכו את המקדמה).<br />
      ** במידה והסטודנט/ית לא מעוניין/ת להמשיך בקורס מכל סיבה שהיא, ניתן לעזוב את הקורס לאחר 9 שבועות ולקבל החזר כספי של 60% מעלות הקורס.
    </LeavingCondition>
  </PriceSectionContainer>
)

export default function OurCourses() {
  const [selectedCourse, setSelectedCourse] = useState(-1);

  return (
    <OurCourseContainer id="our_courses">
      <OurCoursesContent>
        <Title>הקורסים שלנו</Title>
        <CoursesContainer>
          {courses.map((course, index) => (
            <Course>
              {index === 0 ? <FECourseTitleBG /> : <FSCourseeTitleBG />}
              <CourseTitle>{course.name}</CourseTitle>
              <CourseDescription>{course.description}</CourseDescription>
              <CourseDuration>משך הקורס: {course.duration}</CourseDuration>
              <CourseOpening>מחזור הבא: {course.opening}</CourseOpening>
              {/* {index === 0 ? (
                <FECourseDetailsButton onClick={() => setSelectedCourse(index)}>
                  למידע נוסף ↓
                </FECourseDetailsButton>
              ) : (
                <FSCourseDetailsButton onClick={() => setSelectedCourse(index)}>
                  למידע נוסף ↓
                </FSCourseDetailsButton>
              )} */}
              {/* {selectedCourse === index && ( */}
              <CourseDetails>
                <CourseDetailsOpener
                  title="מידע כללי"
                  content={course.fullDescription}
                />
                <CourseDetailsOpener
                  title="סילבוס"
                  content={course.sylabus.map((sylItem) => (
                    <li>{sylItem}</li>
                  ))}
                />
                <CourseDetailsOpener
                  title="דרישות מוקדמות לקורס"
                  content={course.requirements}
                />
                <CourseDetailsOpener
                  title="מחירון"
                  content={<PriceSection {... course} />}
                />
              </CourseDetails>
              {/* )} */}
              {index === 0 ? (
                <FEContactUsButton onClick={() =>  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>השאר/י פרטים >></FEContactUsButton>
              ) : (
                <FSContactUsButton onClick={() =>  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>השאר/י פרטים >></FSContactUsButton>
              )}
            </Course>
          ))}
        </CoursesContainer>
      </OurCoursesContent>
    </OurCourseContainer>
  );
}
