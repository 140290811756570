import React from "react";
import styled from "styled-components";

const OurStaffContainer = styled.div`
  background: url("/techbg4.jpeg");
  padding: 80px 0 120px;

  @media (max-width: 1200px) {
    padding: 40px 0;
  }
`;

const OurStaffContent = styled.div`
  margin-bottom: 40px;
  width: 1020px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  color: white;
  margin: 0;
  padding-bottom: 60px;
`;

const TeamMembersContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    display: block;
  }
`;

const TeamMember = styled.div`
  border-radius: 20px;
  padding: 20px;
  background: white;
  box-shadow: 2px 4px 25px 2px rgba(255, 255, 255, 0.25);
  position: relative;
  width: 430px;

  @media (max-width: 1200px) {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
`;

const TeamMemberUpperPart = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TeamMemberDetails = styled.div`
  width: calc(100% - 200px);

  @media (max-width: 1200px) {
    width: calc(100% - 160px);
  }
`;

const TeamMemberPhoto = styled.div`
  background: url("/Nofar.png");
  width: 178px;
  height: 178px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;

  @media (max-width: 1200px) {
    width: 149px;
    height: 149px;
  }
`;

const TeamMemberPhotoMan = styled(TeamMemberPhoto)`
  background-image: url('/Bar.png');
  background-size: 86%;
  background-position-y: bottom;
  background-repeat: no-repeat;
`

const TeamMemberName = styled.div`
  background: #5271ff;
  color: white;
  height: 48px;
  line-height: 48px;
  font-size: 26px;
  font-weight: 700;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  margin-right: -20px;
  padding: 0 20px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  display: inline-block;
  margin-bottom: 12px;
`;

const TeamMemberTitles = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #0e132b;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const TeamMemberDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: #0e132b;
  padding: 20px 0 0 0;
  text-align: justify;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

export default function OurStaff() {
  return (
    <OurStaffContainer id="our_staff">
      <OurStaffContent>
        <Title>צוות המרצים</Title>
        <TeamMembersContainer>
          <TeamMember>
            <TeamMemberUpperPart>
              <TeamMemberDetails>
                <TeamMemberName>בר קדם</TeamMemberName>
                <TeamMemberTitles>
                  מרצה - Full Stack
                  <br/>
                    9 שנות ניסיון בפיתוח, ניהול צוותי פיתוח, העברת הכשרות
                    ויזמות בהייטק
                </TeamMemberTitles>
              </TeamMemberDetails>
              <TeamMemberPhotoMan />
            </TeamMemberUpperPart>
            <TeamMemberDescription>
מפתח Full Stack בכיר עם ניסיון בחברות הייטק בגדלים שונים - החל מסטרטאפים קטנים בשנים הראשונות להקמתם, ועד לחברות גדולות הנסחרות בבורסה. בנוסף, הקים והיה שותף טכנולוגי בהקמת סטרטאפ, ניהל צוות פיתוח המכיל מפתחים, QA ומנהל מוצר. כחלק מתפקיד זה, ליווה את הצוות מקצועית ואישית. בנוסף, עבד למול לקוחות שונים כפרילנסר והעביר הכשרות פיתוח Full Stack ו-Front-End.
            </TeamMemberDescription>
          </TeamMember>
          <TeamMember>
            <TeamMemberUpperPart>
              <TeamMemberDetails>
                <TeamMemberName>נופר בן דוד</TeamMemberName>
                <TeamMemberTitles>
                  מרצה - Front-End
                  <br/>
                    9 שנות ניסיון בפיתוח Front-End ובתפקידי הדרכה והובלה טכנולוגית
                </TeamMemberTitles>
              </TeamMemberDetails>
              <TeamMemberPhoto />
            </TeamMemberUpperPart>
            <TeamMemberDescription>
              מפתחת Front-End בכירה ומובילה טכנולוגית עם ניסיון רב בסטרטאפים בינוניים וגדולים. בעלת ניסיון בפיתוח עבור לקוחות, בהבנת צרכי לקוח ומימושם כחלק מצוות חזק שנותן פתרונות טכנולוגיים לקוחות. כחלק מתפקיד זה, שימשה גם כיועצת טכנולוגית, מרצה ומעבירה הכשרות בפיתוח צד לקוח. בנוסף, בשנים האחרונות משמשת כמנטורית עבור מפתחים צעירים בתחילת ובמהלך דרכם המקצועית.
            </TeamMemberDescription>
          </TeamMember>
        </TeamMembersContainer>
      </OurStaffContent>
    </OurStaffContainer>
  );
}
