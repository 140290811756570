import React from "react";
import styled from "styled-components";

// background: url('https://www.shutterstock.com/shutterstock/photos/1716024535/display_1500/stock-photo-online-training-young-guy-learns-online-by-video-conference-in-zoom-app-on-the-screen-the-1716024535.jpg');
const AboutUsContainer = styled.div`
  height: 900px;
  width: 100%;
  background: url("/techbg.png");
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
  padding-top: 20px;
  color: white;
  background-size: cover;
  position: relative;
  z-index: 0;

  @media (max-width: 1200px) {
    height: auto;
    padding-bottom: 20px;
  }
`;

const AboutUsCover = styled.div`
  width: 100%;
  height: 100%;
  background: url(/techbg2.png);
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0;
`;

const AboutUsContent = styled.div`
  z-index: 1;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const MainTitle = styled.h1`
  margin: 40px auto 60px auto;
  text-align: center;
  font-size: 44px;
  width: 1170px;

  @media (max-width: 1200px) {
    margin: 20px auto 40px;
    font-size: 28px;
    width: 100%;
  }
`;

const Items = styled.div`
  width: 100%;
  position: relative;
`;

const FirstItemTitleBG = styled.div`
  width: calc(50% - 40px);
  height: 42px;
  background: white;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SecondItemTitleBG = styled.div`
  width: 49%;
  height: 42px;
  background: white;
  top: 120px;
  left: 0;
  position: absolute;
  z-index: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const FourthItemTitleBG = styled.div`
  width: 49%;
  height: 42px;
  background: white;
  top: 360px;
  left: 0;
  position: absolute;
  z-index: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const ThirdItemTitleBG = styled.div`
  width: calc(50% - 40px);
  height: 42px;
  background: white;
  top: 240px;
  right: 0;
  position: absolute;
  z-index: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const ItemsContent = styled.div`
  width: 1170px;
  position: relative;
  margin: auto;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const Item = styled.div`
  position: absolute;

  @media (max-width: 1200px) {
    top: 0 !important;
    width: auto !important;
    position: relative !important;
    margin-bottom: 36px !important;
  }
`;

const FirstItem = styled(Item)`
  top: 0;
  right: 0;
  width: 570px;
`;

const SecondItem = styled(Item)`
  top: 120px;
  left: 0;
  width: 530px;
`;

const ThirdItem = styled(Item)`
  top: 240px;
  right: 0;
  width: 570px;
`;

const FourthItem = styled(Item)`
  top: 360px;
  left: 0;
  width: 530px;
`;

const ItemTitle = styled.div`
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
  margin: 0 144px 20px 0;
  color: #5271ff;
  border-radius: 8px;

  @media (max-width: 1200px) {
    display: inline-block;
    width: calc(100% - 40px);
    margin: 0 12px 12px 0;
    padding: 0 20px;
    font-size: 26px;
    line-height: 36px;
    background: white;
    margin-right: -20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const EvenItemTitle = styled(ItemTitle)`
  margin: 0 0 20px 0;

  @media (max-width: 1200px) {
    width: calc(100% - 20px);
    margin: 0 20px 12px 0;
    margin-left: -20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

const ItemImage = styled.div`
  width: 110px;
  height: 110px;
  background: rgba(82, 113, 255, 0.2);
  border-radius: 50%;
  border: 3px solid white;

  &:before {
    display: block;
    position: relative;
    content: "";
    background-position: center center;
    background-size: 70%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

const FirstImage = styled(ItemImage)`
  &:before {
    background-image: url("Community.png");
  }
`;

const SecondImage = styled(ItemImage)`
  &:before {
    background-image: url("FlexibleTime.png");
    background-position: 54% 42%;
  }
`;

const ThirdImage = styled(ItemImage)`
  &:before {
    background-image: url("Mentoring.png");
    background-position-y: 35%;
  }
`;

const FourthImage = styled(ItemImage)`
  &:before {
    background-image: url("Professional.png");
  }
`;

const ItemText = styled.div`
  font-size: 20px;
  color: white;
  width: 400px;
  padding-right: 30px;
  text-align: justify;

  @media (max-width: 1200px) {
    font-size: 18px;
    width: calc(100% - 40px);
    box-sizing: border-box;
    padding-right: 0;
  }
`;

const EvenItemText = styled(ItemText)`
  padding-left: 30px;
  padding-right: 0;
  
  @media (max-width: 1200px) {
    width: 100%;
    padding-left: 0;
    padding-right: 40px;
  }
`;

export default function AboutUs() {
  return (
    <AboutUsContainer id="about_us">
      <AboutUsCover />
      <AboutUsContent>
        <MainTitle>
          קורסים מעמיקים ללימוד פיתוח תוכנה
          <br />
          במודל למידה חכם וזול יותר
        </MainTitle>
        <Items>
          <FirstItemTitleBG />
          <SecondItemTitleBG />
          <ThirdItemTitleBG />
          <FourthItemTitleBG />
          <ItemsContent>
            <FirstItem>
              <ItemTitle>שיעורים בקבוצות קטנות</ItemTitle>
              <ItemContent>
                <FirstImage />
                <ItemText>
                  בכל שבוע מתקיימים שיעורים בזום עם מרצה הקורס בקבוצות של עד 10
                  סטודנטים. מטרת השיעורים בקבוצה היא לאפשר למרצה להכיר את
                  הסטודנטים, לעזור להם ולתת זמן לשאילת שאלות על תכני ההרצאות
                  המוקלטות ושיעורי הבית.
                </ItemText>
              </ItemContent>
            </FirstItem>
            <SecondItem>
              <EvenItemTitle>למידה בזמנים גמישים</EvenItemTitle>
              <ItemContent>
                <EvenItemText>
                  הקורסים משלבים שיעורים מוקלטים, בהם ניתן לצפות בקצב ובזמן
                  המתאים לכל סטודנט, ושיעורים שבועיים בזום בשעות הערב הנוחות
                  ומתאימות לאלו שעדיין עובדים או לומדים במקביל להשתתפות בקורס.
                </EvenItemText>
                <SecondImage />
              </ItemContent>
            </SecondItem>
            <ThirdItem>
              <ItemTitle>ליווי אישי ובניית מחויבות</ItemTitle>
              <ItemContent>
                <ThirdImage />
                <ItemText>
                  המרצה מלווה את הסטודנטים בשיעורים הקבוצתיים, ומנהל עם כל
                  סטודנט שיחה אישית אחת לחודש על מנת לעקוב אחרי ההתקדמות, ולתת
                  הכוונה מקצועית ואישית מול האתגרים בתהליך הלמידה. בנוסף, הקורס
                  משלב יישום שיטות להתמדה ובניית מחויבות לשיפור ולמידה.
                </ItemText>
              </ItemContent>
            </ThirdItem>
            <FourthItem>
              <EvenItemTitle>מרצים מנוסים בתעשייה</EvenItemTitle>
              <ItemContent>
                <EvenItemText>
                  המרצים שלנו בעלי ניסיון רב בפיתוח בשוק ההייטק, וכן בהעברת
                  הרצאות והכשרות. אנו מבטיחים בכך קורסים ברמה גבוהה ביותר,
                  עדכניים לשוק העבודה, המשלבים טכנולוגיות מתקדמות, שיטות עבודה
                  עדכניות ושימוש נכון וחכם בכלי AI כמו ChatGPT.
                </EvenItemText>
                <FourthImage />
              </ItemContent>
            </FourthItem>
          </ItemsContent>
        </Items>
      </AboutUsContent>
    </AboutUsContainer>
  );
}
