import React, { useEffect } from "react";
import styled from "styled-components";

const TermsContainer = styled.div`
  width: 1020px;
  margin: 80px auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
    margin: 30px auto;
    box-sizing: border-box;
  }
`;

const TermsTitle = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  text-decoration: underline; 

  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

const TermsContent = styled.div`
  font-size: 20px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`

export default function Terms() {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])
  return (
    <TermsContainer>
      <TermsTitle>תקנון, תנאי שימוש ומדיניות החזר</TermsTitle>
      <TermsContent>
        <b>
      אין להעתיק, להוריד, לשמור למחשב, להעביר, לערוך מחדש, לשנות, להפיץ את התכנים או לסחור בהם עם אדם אחר בכל צורה או אמצעי, כל עוד לא קיבלת אישור ממרצה הקורס.  קיום אחד מאלו מהווה הפרת זכויות יוצרים. </b><br /><br/>
הקורס משלב הרצאות וידאו מוקלטות, הנועדות לצפייה בזמנו החופשי של הסטודנט מדי שבוע, שיעורים שבועיים בזום עם מרצה הקורס, ושיעורי בית תרגול החומר הנלמד. <br/>
להבטחת מקום הסטודנט בקורס, עליו לשלם מקדמה של 600 ש״ח. את התשלום המלא יש לשלם עד 5 ימי עסקים לפני תאריך פתיחת הקורס. במידה של איחור בתשלום המלא, הצטרפות לקורס תהיה על בסיס מקום פנוי, או שתוצע הצטרפות למחזור הבא שעתיד להיפתח. <br />
במידה ולאחר הרשמה לקורס (בין אם ע״י תשלום מקדמה או תשלום מלא) - הקורס לא נפתח, עקב מספר מועט של נרשמים או כל סיבה אשר תהא, יוחזרו תשלומי הסטודנט עבור הקורס במלואם לסטודנט. <br />
מרצה הקורס אחראי על העברת השיעור הקבוצתי בזום למשך 90 דק׳, זהו זמן מרוכז לשאלות, דגשים, ומעבר על שיעורי הבית של אותו השבוע. בנוסף, המרצה אחראי על קיום שיחות אישיות של כחצי שעה אחת לחודש עם כל סטודנט, בהן המטרה לקדם ולעזור לסטודנט בתהליך הלמידה שלו. מעבר לכך, כל עזרה נוספת מצדו של המרצה היא בזמנו הפרטי ומבחירתו, ואינה חלק מתוכנית הקורס. עם זאת, יש לציין כי הקורסים בנויים באופן המאפשר לסטודנטים להיעזר זה בזה, ולצד השיעורים השבועיים המאפשרים חידודים והכוונה מצד מרצה הקורס, יש כלים מספקים להתקדמות מיטבית בתהליך הלמידה.<br />
יש לשמור על תרבות דיון נעימה בקבוצת הפייסבוק, אין להעלות תוכן פרסומי (המעודד רכישת מוצר או שירות באופן ישיר או עקיף) או חומרי הוראה חיצוניים (כולל כאלו שהופקו על ידי הסטודנט עצמו) בקבוצה ללא אישור של מרצה הקורס, אין להעלות תכנים פוליטיים לקבוצה, אין לכתוב דברים בעלי אופי העלול לגרום פגיעה באף אדם, גוף או גורם עסקי, יש להשאיר את הקבוצה מקום נעים וחברי המתעסק בתכני הקורס בלבד. <br/><br/>
<b>מדיניות החזר:</b><br />
הצלחת המשתתף תלויה בהתמדתו והשקעתו האישית ואין ביכולתנו להתחייב לתוצאות בטוחות. <br />
ל-TeckStack.academy הזכות לזכות את הלקוח בחלק היחסי של מספר השיעורים שנותרו (החזר כספי חלקי) מתוך סך השיעורים עליהם שילם הסטודנט תשלום מלא ולבטל את ההתקשרות והחברות בקבוצת הלימוד בכל שלב ומכל סיבה. <br />
ההתחברות לקבוצת הלימוד והשיעורים דורש פרופיל פייסבוק – לא ניתן לקבל את השיעורים למייל או לוואטסאפ. <br />
הגבלת אחריות: TechStack.academy לא אחראית על כל נזק אפשרי העלול להיגרם מהשימוש בתכני הקורס או בפלטפורמות הרישום והצפייה בקורס. השימוש בכל אלה הוא באחריות המשתמש בלבד. <br />
העלאת תכנים המוגנים בזכויות יוצרים לקבוצה היא באחריות המשתתף שהעלה אותם. <br />
(כולל פיצוי TechStack.academy בעבור כל נזק שיגרם עקב כך.) 
 אין הגבלת זמן לצפייה בחומרי הלימוד והחברות בקבוצה באופן עקרוני – אך לצורך התחייבות עסקית, <br />
 אנו מתחייבים על זמן סביר של כ-12 חודשים ממועד שיעור הזום האחרון עם המרצה, בהם החומר יהיה זמין עבור הסטודנט, למקרה ויהיה שינוי כלשהו בפעילות העסק. <br />


הגבלת גיל: ההרשמה וההזמנה מעל גיל 18 בלבד, במידה והקורס מאפשר - הרשמה של קטין לפרוייקט תעשה באמצעות הורה \ אפוטרופוס, השגחתו ובאחריותו האישית. <br />< br/>
<b>
* תשלום והצטרפות לקורס מהווים הסכמה לפרטי המדיניות והתקנון
</b>
      </TermsContent>
    </TermsContainer>
  );
}
