import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  line-height: 70px;
  display: flex;
  background: white;
  position: fixed;
  z-index: 3;
  box-shadow: 2px 2px 18px 2px rgba(0,0,0,0.25);
`;

const LogoContainer = styled.div`
  padding: 0 0 0 40px;
`;

const Logo = styled.div`
  width: 230px;
  height: 100%;
  background: url("Academy.png");
  background-position: center -68px;
  background-size: 100%;

  @media (max-width: 1200px) {
    margin-right: -20px;
  }
`;

const MenuOptions = styled.div`
  height: 100%;
  display: flex;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const MenuOption = styled.div`
  padding: 0 20px;
  font-size: 20px;
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  cursor: pointer;

  transition: padding 300ms ease;
  &:hover {
    padding: 4px 20px;
  }
`;

const CTA = styled.div`
  background: #e24513;
  color: white;
  height: 28px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  position: absolute;
  top: 8px;
  left: 20px;
  padding: 12px 20px;
  border-radius: 12px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: box-shadow 300ms ease;

  &:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
  }
`;

const SelectionBarContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
  height: 6px;
`;

const Selector = styled.div`
  position: absolute;
  bottom: 0;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: 6px;
  border-radius: 4px;
  background: #5271ff;
  transition: all 500ms ease;
`;

const SelectionBar = ({ left, width }) => {
  return (
    <SelectionBarContainer>
      <Selector left={left} width={width} />
    </SelectionBarContainer>
  );
};

export default function Header() {
  const [selectedSection, setSelectedSection] = useState("");
  const [left, setLeft] = useState();
  const [width, setWidth] = useState();
  const [isUserScrolling, setIsUserScrolling] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;

  const selectMenuItem = (menuItem) => {
    console.log("menuItem", menuItem);
    const menuElement = document.getElementById(`${menuItem}_menu`);
    setLeft(menuElement.offsetLeft);
    setWidth(menuElement.offsetWidth);
    setSelectedSection(menuItem);
  };

  const onItemClick = (id, left, width, triggerScroll) => {
    navigate("/");
    setTimeout(() => {
      if (triggerScroll) {
        document
          .getElementById(id)
          .scrollIntoView({ behavior: "smooth", block: "start" });
        setIsUserScrolling(false);
        setTimeout(() => {
          setIsUserScrolling(true);
        }, 1000);
      }
      setLeft(left);
      setWidth(width);
      setSelectedSection(id);
    }, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position of the window
      if (!isUserScrolling || currentPathname !== "/") return;
      const scrollY = window.scrollY;

      // Define the sections and their offsets
      const sections = [
        {
          id: "about_us",
          offset: document.getElementById("about_us").offsetTop,
        },
        {
          id: "our_courses",
          offset: document.getElementById("our_courses").offsetTop,
        },
        {
          id: "our_staff",
          offset: document.getElementById("our_staff").offsetTop,
        },
        { id: "faq", offset: document.getElementById("faq").offsetTop },
        {
          id: "contact_us",
          offset: document.getElementById("contact_us").offsetTop,
        },
        // Add more sections as needed
      ];

      // Determine the active section based on the scroll position
      let activeSection = null;
      for (const section of sections) {
        if (scrollY >= section.offset - 70) {
          activeSection = section.id;
        }
      }

      // Update the active menu item
      if (activeSection && activeSection !== selectedSection) {
        selectMenuItem(activeSection);
      }
    };

    // Attach the scroll listener
    window.addEventListener("scroll", handleScroll);

    // Call the handleScroll function once to initialize the active menu item
    handleScroll();

    // Remove the scroll listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isUserScrolling, selectedSection, currentPathname]);

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <Logo />
        </Link>
      </LogoContainer>
      <MenuOptions>
        <SelectionBar left={left} width={width} />
        <MenuOption
          id="about_us_menu"
          isSelected={selectedSection === "about_us"}
          onClick={(e) => {
            onItemClick(
              "about_us",
              e.target.offsetLeft,
              e.target.offsetWidth,
              true
            );
          }}
        >
          מי אנחנו
        </MenuOption>
        <MenuOption
          id="our_courses_menu"
          isSelected={selectedSection === "our_courses"}
          onClick={(e) =>
            onItemClick(
              "our_courses",
              e.target.offsetLeft,
              e.target.offsetWidth,
              true
            )
          }
        >
          הקורסים
        </MenuOption>
        <MenuOption
          id="our_staff_menu"
          isSelected={selectedSection === "our_staff"}
          onClick={(e) =>
            onItemClick(
              "our_staff",
              e.target.offsetLeft,
              e.target.offsetWidth,
              true
            )
          }
        >
          המרצים
        </MenuOption>
        <MenuOption
          id="faq_menu"
          isSelected={selectedSection === "faq"}
          onClick={(e) =>
            onItemClick("faq", e.target.offsetLeft, e.target.offsetWidth, true)
          }
        >
          שאלות נפוצות
        </MenuOption>
        <MenuOption
          id="contact_us_menu"
          isSelected={selectedSection === "contact_us"}
          onClick={(e) =>
            onItemClick(
              "contact_us",
              e.target.offsetLeft,
              e.target.offsetWidth,
              true
            )
          }
        >
          צור קשר
        </MenuOption>
      </MenuOptions>
      <CTA
        onClick={() => {
          const menuElement = document.getElementById(`contact_us_menu`);
          onItemClick(
            "contact_us",
            menuElement.offsetLeft,
            menuElement.offsetWidth,
            true
          );
        }}
      >
        השאר/י פרטים
      </CTA>
    </HeaderContainer>
  );
}
