import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ContactUsContainer = styled.div`
  background: url("https://www.productexperts.co.il/wp-content/uploads/2020/04/product_experts4-1.jpg");
  padding: 80px 0;
  min-height: 80vh;

  @media (max-width: 1200px) {
    padding: 40px 20px;
  }
`;

const ContactUsContent = styled.div`
  margin-bottom: 40px;
  width: 594px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.25);
  padding: 28px;
  border-radius: 20px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 20px;
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  color: white;
  margin: 0 0 28px 0;
  text-align: center;
`;

const FormTitle = styled.div`
  background: white;
  width: 100%;
  height: 41px;
  line-height: 41px;
  color: #5271ff;
  border-radius: 12px;
  font-size: 26px;
  font-weight: 700;
  margin: auto;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const RowTitle = styled.div`
  font-size: 26px;
  color: white;
  font-weight: 400;
  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

const RowInputContainer = styled.div`
  width: 370px;
  border-radius: 12px;
  background: white;

  input {
    height: 45px;
    width: 100%;
    font-size: 22px;
    box-sizing: border-box;
    border: none;
    background: rgba(0, 0, 0, 0);
    padding-right: 12px;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  @media (max-width: 1200px) {
    width: calc(100% - 90px);
  }
`;

const SubmitButton = styled.button`
  background: #e24513;
  height: 63px;
  font-size: 30px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  color: white;
  width: 200px;
  margin: auto;
  border-radius: 12px;
  border: none;
  display: block;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: box-shadow 300ms ease;

  &:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 1200px) {
    height: 48px;
    line-height: 48px;
    width: 160px;
  }
`;

const Links = styled.div`
  margin: 180px auto 0px;
  display: flex;
  justify-content: center;
  text-align: center;

  a {
    color: white !important;
    margin: 0 12px;
  }
`;

export default function ContactUs() {
  return (
    <ContactUsContainer id="contact_us">
      <ContactUsContent>
        <Title>צור קשר</Title>
        <FormTitle>השאירו פרטים ונחזור אליכם בהקדם!</FormTitle>
        <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeAeNewOYFwVSkVAZ2jgTvnoZded3Lcj6YO4-2EngQ1mqXeMg/formResponse">
          <FormRow>
            <RowTitle>שם מלא</RowTitle>
            <RowInputContainer>
              <input type="text" name="entry.1383332890" required />
            </RowInputContainer>
          </FormRow>
          <FormRow>
            <RowTitle>אימייל</RowTitle>
            <RowInputContainer>
              <input type="email" name="entry.1312271299" required />
            </RowInputContainer>
          </FormRow>
          <FormRow>
            <RowTitle>טלפון</RowTitle>
            <RowInputContainer>
              <input type="tel" name="entry.1648165189" required />
            </RowInputContainer>
          </FormRow>
          <SubmitButton type="submit">שלח/י</SubmitButton>
        </form>
      </ContactUsContent>
      <Links>
        <Link to="/terms-and-conditions">תקנון</Link>
        <Link to="/privacy-policy">מדיניות פרטיות</Link>
      </Links>
    </ContactUsContainer>
  );
}
