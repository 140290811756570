import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";
import styled from "styled-components";
import AboutUs from "./AboutUs";
import OurCourses from "./OurCourses";
import OurStaff from "./OurStaff";
import FAQ from "./FAQ";
import ContactUs from "./ContactUs";
import Terms from "./Terms";
import Privacy from "./Privacy";

const AppContainer = styled.div`
  direction: rtl;
  font-family: main-font;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 70px;
  z-index: 1;
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <Header />
        <ContentContainer>
          <Routes>
            <Route path="/" element={<>
              <AboutUs />
              <OurCourses />
              <OurStaff />
              <FAQ />
              <ContactUs />
            </> } />
            <Route path='/terms-and-conditions' element={<Terms />}/>
            <Route path='/privacy-policy' element={<Privacy />}/>
            {/* Add more routes as needed */}
          </Routes>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
}

export default App;
