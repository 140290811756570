import React from "react";
import styled from "styled-components";
import ContentOpener from "./ContentOpener";

const FAQContainer = styled.div`
  background: url("/techbg3.png");
  padding: 80px 0;

  @media (max-width: 1200px) {
    padding: 40px 0;
  }
`;

const FAQContent = styled.div`
  margin-bottom: 40px;
  width: 1020px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  color: #0e132b;
  margin: 0;
  padding-bottom: 60px;
`;

const FAQOpener = styled(ContentOpener)`
  margin-bottom: 12px;
`;

const QuestionsList = [
  {
    question: "האם אני מתאים/ה לקורס?",
    answer: (
      <div>
        בתיאור הקורסים ניתן לראות מהן הדרישות המוקדמות לקבלה לקורס. קורס הבסיס
        Front-End שלנו מתאים לחסרי רקע בתחום, אך כן נדרשת רמת אנגלית גבוהה
        ויכולת אנליטית. הקורסים המתקדמים שלנו דורשים רקע קודם כפי שמצויין בתיאור
        של כל אחד מהקורסים באתר.
      </div>
    ),
  },
  {
    question: "האם יש מבחני קבלה לקורס?",
    answer: (
      <div>
        אנחנו לא מתנים הצטרפות לקורס במבחן קבלה, אך לפני ההצטרפות לקורס נקיים
        איתכם שיחה בה נוכל להבין באופן פרטני האם הקורס מתאים לכם, מהן הציפיות
        שלנו ושלכם מההצטרפות לקורס וכו׳. אין ברצוננו לצרף לקורסים סטודנטים
        שאיננו מאמינים ביכולות שלהם להתמודד איתם, הן בזמן והן בניסיון ויכולות
        רלוונטיות.
      </div>
    ),
  },
  {
    question: "מה ניתן לעשות אם במהלך הקורס איני מעוניינ/ת להמשיך?",
    answer: (
      <div>
        המטרה שלנו היא לייצר תשתית למידה טובה, ולשקף לסטודנטים את התוכנית
        הלימודית והאתגרים שבה בצורה מדוייקת וטובה ככל האפשר, כדי למנוע פערים
        בציפיות ולשמור על קורס פעיל ויעיל לכולם. עם זאת, לעיתים בנסיבות שונות
        האתגר גדול יותר מהמצופה לסטודנט, אם זה ברמה של הקורס, בזמן השקעה הנדרש,
        או ברמת העניין של הסטודנט בתכני הקורס. לכן, לאחר 9 שבועות מתחילת הקורס
        אנו מאפשרים לעזוב את הקורס ולקבל החזר כספי של 60% מעלותו. הסיבה לנק׳ זמן
        זו של 9 שבועות היא שבפרק זמן זה נאמוד את היכולת שלנו לעזור לכם באתגרים,
        וגם אתם תוכלו לזכות לחלק המבוא של הקורס שעל פיו תוכלו להבין יותר על תחום
        זה.
      </div>
    ),
  },
  {
    question: "האם אהיה מוכן בסוף הקורס למצוא עבודה בתחום?",
    answer: (
      <div>
        הקורסים הינם מעמיקים ונותנים בסיס אפשרי למציאת עבודה בתחום. קורס פיתוח
        Front-End הבסיסי יתן לכם את ההיכרות הבסיסית הנדרשת עם עולם פיתוח צד
        לקוח. עם זאת, אנו ממליצים להמשיך לקורסי ההרחבה שלנו, ע״פ נטיית לבכם לאחר
        היכרות עם קורס הבסיס. הקורסים האפשריים הם Front-End מתקדמים, אשר מקנה
        ידע נוסף ומעמיק בעולמות הFront-End, או קורס Full Stack, אשר מחבר את
        הבסיס שרכשתם צד הלקוח עם צד השרת. בקורסים המעמיקים האלו אנחנו מקנים ידע
        נוסף, הכוונה בהכנה לראיונות עבודה, שימוש ולמידה של הכלים ושיטות העבודה
        העדכניות והמקובלות בשוק ההייטק.
      </div>
    ),
  },
  {
    question: "האם המרצים של הקורס הם אותם מרצים של השיעורים המוקלטים?",
    answer: (
      <div>
        כן. אנחנו רואים חשיבות רבה במתן הכוונה והנגשת ידע מאנשי המקצוע המנוסים
        בצוות המרצים שלנו. יתכן והשיעורים המוקלטים יהיו של מרצה אחד והשיעורים
        בזום עם מרצה אחר, אך שניהם יהיו בהכרח מצוות המרצים המוצג באתר.
      </div>
    ),
  },
  {
    question: "כמה שעות בשבוע נדרשות ממני כדי להצליח בקורס?",
    answer: (
      <div>
        כמות הזמן הנדרשת משתנה כמובן מסטודנט לסטודנט. כן אפשר להניח כמות השקעה
        מינימלית של שעות בשבוע: שעה של הרצאה מוקלטת, שעה וחצי של שיעור בזום עם
        המרצה, 4 שעות לשיעורי בית. כלומר כ-6.5 שעות בשבוע סה״כ - אותם מומלץ
        לקבוע לעצמכם כזמנים קבועים מראש ביומן כך שתדעו שאכן יש לכם את הזמן
        הנדרש. אם זוהי כמות שעות גדולה מדי עבורכם, כנראה שיהיה לכם קשה להתקדם
        בקצב הקורס.
      </div>
    ),
  },
  {
    question: "האם יש אופציה להירשם לשיעור ניסיון?",
    answer: (
      <div>
        למרות שהיינו שמחים לאפשר לסטודנטים לטעום מחוויית הקורסים שלנו, לצערנו
        אין אפשרות להירשם לשיעור ניסיון מכמה סיבות:
        <ul>
          <li>
            השיעורים הם חלק מקבוצות קטנות, ושיעורי ניסיון יאלצו להגדיל את כמות
            הסטודנטים בכל קבוצה, מה שישנה את פורמט הקורס הרצוי.
          </li>
          <li>
            שיעור ראשון לא נותן מספיק אינדיקציה לסטודנטים על איכות הקורס וההתאמה
            שלהם אליו, ולדעתינו לא יהיה נכון לשקול הצטרפות לקורס על סמך שיעור
            אחד של מבוא.
          </li>
          <li>
            אנחנו מאמינים בתהליכי בניית מחוייבות ללמידה בקורס, ובהעמקת הידע. אלו
            הם דברים שדורשים זמן, ולכן אנו מצפים מהסטודנטים המצטרפים להגיע עם
            הרצון להעמיק ולהקדיש זמן כדי לראות תוצאות.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question:
      "אני לא בטוח/ה שארצה לעבוד בתחום, אבל כן מעניין אותי ללמוד.",
    answer: (
      <div>
        הקורסים שלנו מותאמים לאלו שמחפשים להשתלב בתעשייה. עם זאת, התכנים מועברים
        בצורה עניינית, מעניינת, ומלווה בתרגילים מאתגרים וכיפיים. בנוסף
        הפרוייקטים במהלך הקורס הם למעשה חוויית יצירה לכל דבר, כך שכל מי שסקרן
        לגבי התחום ומעוניין להעשיר את הידע שלו, וללמוד איך ליצור בעולם התוכנה -
        יהנה מהקורס.
      </div>
    ),
  },
  {
    question: "לאלו גילאים מתאימים הקורסים?",
    answer: (
      <div>
        הקורסים מיועדים לגילאי 18+, ומעבר לכך כל גיל הוא גיל טוב עם סקרנות
        והבסיס הנדרש לכל קורס.
      </div>
    ),
  },
  {
    question: "מהן אפשרויות התשלום?",
    answer: (
      <div>
        את התשלום המלא לקורס ניתן לשלם בהעברה בנקאית או באשראי עד 6 תשלומים. את
        תשלום המקדמה ניתן גם להעביר בביט.
      </div>
    ),
  },
  {
    question: "האם יש הנחה אם אני מצרפ/ת חבר/ה?",
    answer: (
      <div>
        כן! כל אדם נוסף שיצטרף דרככם לקורס, יזכה אתכם ואותו בהנחה של 200 ש״ח
        ממחיר הקורס.
      </div>
    ),
  },
  {
    question: "מה ההבדל בין Front-End ל-Full Stack?",
    answer: (
      <div>
        פרונט-אנד (צד לקוח) מתייחס לקוד הרץ על הדפדפן ואחראי על הצד הויזואלי של
        האתר/אפליקציית Web. החל מאיך נראה כל אלמנט ואיפה הוא יושב, ועד ללוגיקה
        של כל פעולת משתמש בדפדפן. בק-אנד (צד שרת) מתייחס לקוד הרץ על גבי שרת
        מרוחק, שמטרתו לנהל לוגיקה שעדיפה או חייבת לקרות בצד של בעל האתר, ולא
        בדפדפן. למשל, ניהול משתמשים ותשלומים - יהיה פן ויזואלי באתר - בו יוצג
        טופס עם מילוי פרטי משתמש או פרטי אשראי (צד לקוח), ובאישור המשתמש המידע
        ישלח לשרת שיבדוק את המידע שהתקבל, ישמור את הפרטים הרלוונטיים בצורה
        מאובטחת, ויוכל בהמשך לזהות משתמש והאם שילם על שירות, ללא תלות בדפדפן
        ממנו הגיע (צד שרת).
      </div>
    ),
  },
  {
    question: "האם הקורסים מחליפים תואר במדעי המחשב?",
    answer: (
      <div>
        לא, הקורס לא מחליף תואר במדעי המחשב ולא מתיימר להחליף תואר מסוג זה. תואר
        אקדמי הינו תואר המכיל בתוכו קורסים רבים בתחומים מגוונים, ומטרתו היא לפני
        הכל לרכוש ידע אקדמי. הקורסים שלנו נועדו להכשרה פרקטית לשוק העבודה, ולכן
        ממוקדים יותר ומעמיקים ספציפית במה שרלוונטי היום בתעשיית ההייטק, בתחום
        אותו הקורס מקיף.
      </div>
    ),
  },
  {
    question: "יהיו מספר שיעורי זום בהם לא אוכל להצטרף, האם ניתן להשלים?",
    answer: (
      <div>
        כל השיעורים יהיו מוקלטים, ויהיו נגישים לסטודנטים של אותה הקבוצה בלבד.
      </div>
    ),
  },
  {
    question: "האם אני יכול לשלב בזמן לימודים או משרה מלאה?",
    answer: (
      <div>
        בהחלט. הקורסים שלנו נועדו לשילוב עם לימודים או משרה מלאה, בזכות המודל
        הגמיש בו הם מתקיימים. כן צריך לקחת בחשבון, שעדיין נדרשת הקדשת זמן של
        לפחות 7 שעות בשבוע.
      </div>
    ),
  },
];

export default function FAQ() {
  return (
    <FAQContainer id="faq">
      <FAQContent>
        <Title>שאלות נפוצות</Title>
        {QuestionsList.map(({ question, answer }) => (
          <FAQOpener title={question} content={answer}></FAQOpener>
        ))}
      </FAQContent>
    </FAQContainer>
  );
}
